//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Model from 'src/models/model-service.js'
import VueRecaptcha from 'vue-recaptcha'
import { date } from 'quasar'
import axios from 'axios'
export default {
  name: 'page-servicio',
  components: {
    VueRecaptcha
  },
  data() {
    return {
      model: new Model(),
      showForm: true,
      options: null,
      confirm: false,
      nameForm: '',
      flagNameForm: false,
      captchaKey: null,
      show: false,
      date: '',
      dateRules: [val => !!val || 'El campo es requerido'],
      isGeolocate: false,
      dealershipsGeo: []
    }
  },
  computed: {
    departments() {
      let items = this.$store.getters['departments/items']
      return items.map(item => {
        return {
          label: item.label,
          value: item.name
        }
      })
    },
    carModels() {
      let items = this.$store.getters['carmodels/all']
      return items.map(item => {
        return {
          label: item.title.toUpperCase(),
          value: item.name
        }
      })
    },
    dealerships() {
      let items = this.$store.getters['dealerships/items']
      let x = items.map(item => item.items)
      x = x.reduce((acc, val) => acc.concat(val), [])
      let dealerships = x.map(item => {
        let clone = Object.assign({}, item)
        clone.id = clone.id.toString()
        return clone
      })
      return dealerships
    }
  },
  mounted() {
    this.options = this.dealerships
    this.model.type = 'SERVICES'
  },
  methods: {
    onSubmit() {
      if (this.captchaKey) {
        this.$q.loading.show()
        this.model.token = this.captchaKey
        this.model.date = `${this.date} 00:00:00`
        this.$axios
          .post('/contacts', this.model)
          .then(() => {
            this.confirm = true
          })
          .catch(e => {
            console.log(e)
          })
          .finally(() => {
            this.$q.loading.hide()
          })
      } else {
        this.$q.notify({
          message: 'Por favor, comprueba que eres un humano.',
          color: 'red-5'
        })
      }
    },
    filterFn(val, update) {
      if (val === '') {
        update(() => {
          this.options = this.isGeolocate
            ? this.dealershipsGeo
            : this.dealerships
        })
        return
      }
      update(() => {
        const needle = val.toLowerCase()
        this.options = this.dealerships.filter(
          v =>
            v.name.toLowerCase().indexOf(needle) > -1 ||
            v.address.toLowerCase().indexOf(needle) > -1
        )
      })
    },
    closeConfirm() {
      this.confirm = false
      this.$router.go()
    },
    verify(response) {
      this.captchaKey = response
    },
    optionsFn(d) {
      let timeStamp = Date.now()
      let now = date.formatDate(timeStamp, 'YYYY/MM/DD')
      return d >= now
    },
    geolocate() {
      if (navigator.geolocation) {
        this.$q.loading.show()
        navigator.geolocation.getCurrentPosition(position => {
          this.isGeolocate = true
          let lat = parseFloat(position.coords.latitude)
          let lng = parseFloat(position.coords.longitude)
          let url = `https://www.suzuki.com.mx/api/v1/dealerships/find/location?latitude=${lat}&longitude=${lng}`
          axios
            .get(url)
            .then(response => {
              this.dealershipsGeo = []
              if (response.data.items.length > 0) {
                let _option = response.data.items
                _option.forEach(states => {
                  states.items.map(items => {
                    this.dealershipsGeo.push({
                      ...items,
                      id: items.id.toString()
                    })
                  })
                })

                this.options = null
                this.options = this.dealershipsGeo
              } else {
                this.$q.notify({
                  message:
                    'No se encotraron concesionarias cercanas a tu ubicacion.',
                  color: 'red-5'
                })
              }
            })
            .catch(error => {
              console.log(error)
            })
            .finally(() => {
              this.$q.loading.hide()
            })
        })
      }
    }
  },
  async preFetch({ store }) {
    try {
      await store.dispatch('departments/query')
      await store.dispatch('dealerships/query')
      await store.dispatch('carmodels/all', {
        sort: 'name',
        limit: 100,
        'filters[where][status][eq]': 'PUBLISHED'
      })
      return true
    } catch (e) {
      return false
    }
  }
}
